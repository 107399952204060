/**
 * HelpModal module
 *
 * Displays a modal window containing helper information for a specified function of the system.
 */
$(document).ready(function () {
    "use strict";

    var cookieName = "bn_incident_panel_visible";
    var $body = $('body');

    if (!$body.hasClass("toggled") && $(window).width() < 768) {
        $body.addClass("toggled");
    }

    // Search bar
    $('.btn-top-search').on('click touchstart', function (e) {
        e.preventDefault();

        var $target = $('.top-search-bar');

        if ($target.hasClass('search-bar-toggle')) {
            $target.removeClass('search-bar-toggle');
        } else {
            $target.addClass('search-bar-toggle');
        }
    });

    $('.left-sidebar-toggle').on('click touchstart', function (e) {
        e.preventDefault();

        var $target = $('.left-sidebar');

        if ($target.hasClass('nav-off-screen')) {
            $target.removeClass('nav-off-screen');
        } else {
            $target.addClass('nav-off-screen');
        }

    });

    // Hide elements on Body click
    $(document).on('click touchstart', function (e) {
        var $target = $(e.target);

        if ($target.closest('.left-sidebar').length == 0 && $target.closest('.left-sidebar-toggle').length == 0) {
            $('.left-sidebar').removeClass('nav-off-screen');
        }

        if ($target.closest('.top-search-bar').length == 0 && $target.closest('.btn-top-search').length == 0) {
            $('.top-search-bar').removeClass('search-bar-toggle');
        }
    });

    $('.user-toggle').on('click', function () {
        $(this).next().find('.dropdown-submenu .dropdown-menu').hide();
    });

    $('.dropdown-submenu a.dropdown-toggle').on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        $('.dropdown-submenu .dropdown-menu').hide();

        $(this).next('ul').toggle();
    });

});

function showEvents() {

    var $target = $('body');
    var cookieName = "bn_incident_panel_visible";

    if ($target.hasClass('toggled')) {
        $target.removeClass('toggled');
    } else {
        $target.addClass('toggled');
    }

    $target.trigger('classChange');
    $.cookie(cookieName, !$target.hasClass("toggled"), {expires: 180});
}

