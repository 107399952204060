$(function () {

    var csrfToken = $('meta[name="YII_CSRF_TOKEN"]').attr('content');
    jQuery.ajax = (function (_ajax) {
        return function (o) {
            var data = {};
            var success = o.success;

            if (typeof o.data !== 'undefined') {
                data = o.data;
            }

            if (String(o.type).toLowerCase() != 'get') {
                data['YII_CSRF_TOKEN'] = csrfToken;
                o.data = data;
            }

            o.success = function(data, textStatus, jqXHR) {
                if (data == '\"guest\"') {
                     window.location.href = '/site/login';
                     return;
                }

                if(success) {
                    success(data, textStatus, jqXHR);
                }
            };

            return _ajax.apply(this, arguments);
        };
    })($.ajax);
});