/**
 * BitNinja Admin Panel Main module*
 *
 * @module main
 */
function fadeElements(result) {
    if ($(result).filter('tr#notFound').length > 0) {
        $('#ipList tr.gradeA').remove();
        $('#ipList').append(result);
        return;
    } else {
        $('#ipList tr#notFound').remove();
    }

    $('#ipList tr.gradeA').each(function () {
        var id = $(this).attr('id');
        if ($(result).filter('tr#' + id).length === 0) {
            $("tr#" + id).fadeOut('fast', function () {
                $(this).remove();
            });
        }
    });

    $(result).filter('tr').each(function () {
        var id = $(this).attr('id');
        if ($('#ipList tr#' + id).length === 0) {
            $(this).prependTo('#ipList').fadeIn('slow');
        }
    });
}


function deleteUserIp(ip, mask, type, beforeSendFunc, successFunc, element) {

    $.ui.confirm("Are you sure you want to remove this IP from " + type + "list?", function (isConfirm) {
        if (isConfirm) {
            $.ajax({
                url: "/ipmanager/deleteIp",
                type: "POST",
                data: {ip: ip, mask: mask, type: type},
                beforeSend: beforeSendFunc,
                success: successFunc
            });
        } else {
            setTimeout(function () {
                $(element).tooltip('hide');
            }, 1);
        }
    });

}

function deleteIpFromIpHistory(element) {
    var ip = $(element).data('ip');
    var type = $(element).data('type');

    $.ui.confirm("Are you sure you want to remove this IP from " + type + "list?", function (isConfirm) {
        if (isConfirm) {
            $.ui.stripeLoadIndicator.show();

            $.ajax({
                url: "/ipmanager/deleteIp",
                type: "POST",
                data: {ip: ip, mask: 32, type: type},
                beforeSend: function () {
                    $.ajax({
                        url: '/search/index/search/ip/criteria/' + ip,
                        type: "POST",
                        success: function () {                            
                            setTimeout(function () {
                                window.location.href = '/search/index/search/ip/criteria/' + ip;
                            }, 5000);                           
                        }
                    });
                }
            });
        } else {
            setTimeout(function () {
                $(element).tooltip('hide');
            }, 1);
        }
    });
}

function addOrRemoveGl(element) {
    $(element).removeAttr('onclick');

    var ip = $(element).data('ip');
    var type = $(element).data('type');
    var action = $(element).data('action');

    $.ui.stripeLoadIndicator.show();

    $.ajax({
        url: "/ipmanager/addOrRemoveGl",
        type: "POST",
        data: {ip: ip, type: type, action: action},
        success: function () {
            setTimeout(function () {
                window.location.href = '/search/index/search/ip/criteria/' + ip;
            }, 8000);
        }
    });
}

function addErrorMessage(selector, element, message) {
    $(selector).addClass("error");
    $(selector).append('<span id="error" class="help-block error">' + message + '</span>');
}

function removeErrorMessage(selector, element) {
    $(selector + ' #error').remove();
    $(selector).removeClass("error");
}

function deleteServer(element) {
    var server_id = $(element).data('server-id');
    if (confirm('Are you sure to delete this server?')) {
        $.ajax({
            url: "/servers/delete",
            type: "POST",
            data: {server_id: server_id},
            beforeSend: function () {
                $(element).parent().parent().fadeOut('fast');
            },
            success: function () {
                setTimeout(function () {
                    $(element).parent().parent().remove();
                    if ($('#example.dataTable tr').length === 1) {
                        window.location.href = 'main/index';
                    }
                    if ($('#server-card-' + server_id).length === 1) {
                        $('#server-card-' + server_id).parent().remove();
                    }
                }, 500);
            }
        });
    }
}

function addCommentToIP(value, mask, type, element) {
    var dialog = new NinjaDialog('ipDialog', true, function () {
            var comment = $('form textarea#comment').val();
            $(element).next().text(comment);
        },
        {
            type: "POST",
            data: {ip: value, mask: mask, type: type, submitButton: true}
        },
        {
            comment: 'form textarea#comment'
        }
    );

    $.ajax({
        url: '/ipmanager/getOrSetIpComment',
        type: "POST",
        data: {ip: value, mask: mask, type: type},
        success: function (result) {
            dialog.setText(result);
        }
    });
}

function showExpiringCardAlert() {
    var dialog = new NinjaDialog('expiringCardAlert', true);
    $.ajax({
        url: '/servers/showExpiringCardAlert',
        type: "POST",
        data: {ajax: true},
        success: function (result) {
            dialog.setText(result);
        }
    });
}

function decodeSpam(element) {
    var decodedLog = $(element).closest("td").children("#decodedlog");
    var log = $(element).closest("td").children("pre");

    if (typeof decodedLog.html() === 'undefined') {
        log.attr("id", "raw");
        log.find('code').removeClass('to-clipboard');

        var logtext = log.children("code").html();

        $.ajax({
            url: '/search/DecodeSpam',
            type: "POST",
            data: {data: logtext},
            success: function (result) {
                log.parent().append($("<pre id='decodedlog'></pre>").append($("<code class='to-clipboard'></code>").html(result)));
                log.hide();
                $(element).children('#text').html("Show raw log");
            }
        });
    } else {
        if (decodedLog.is(":visible")) {
            log.show();
            log.find('code').addClass('to-clipboard');

            decodedLog.hide();
            decodedLog.find('code').removeClass('to-clipboard');
            $(element).children('#text').html("Decode attack");
        } else {
            log.hide();
            log.find('code').removeClass('to-clipboard');

            decodedLog.show();
            decodedLog.find('code').addClass('to-clipboard');
            $(element).children('#text').html("Show raw log");
        }
    }
}

$('.nav.nav-pills a').click(function (e) {
    if (typeof $(this).tab === "function") {
        $(this).tab('show');
    }

    var scrollmem = $('body').scrollTop();

    window.location.hash = this.hash;

    $("html, body").scrollTop(scrollmem);
});

function toggleUserWindow(field, element) {
    var $this = $(element);
    var $callout = $this.parent('.callout');

    if ($callout.length == 0) {
        $callout = $this.next('.callout');
    }

    $.ajax({
        method: 'POST',
        url: '/users/toggleWindow',
        data: {
            field: field
        }
    }).done(function () {
        slideHelpButton($callout);
        slideHelpButton($callout.prev());
    });
}

function slideHelpButton(element) {
    element.slideToggle(0, function () {
        if (element.is(':visible')) {
            element.css('display', 'inline-block');
        }
    });
}

function saveModule($form, beta, reload) {
    reload = typeof reload !== 'undefined' ? reload : false;
    var server_id = $form.find('[data-server-id]').data('server-id');
    var property = $form.find('[data-module-name]').data('module-name');
    var state = $form.find('input:checkbox').prop('checked');
//    var $waf = $('#server_' + server_id + '_waf');

    if (state) {
        if (property === 'waf_manager') {
            swal({
                title: "After you have enabled WAF, please test it on one of your domains which resides on the server, you can use the <a href=\"https://doc.bitninja.io/docs/installation/testing/#testing-the-web-application-firewall\" target=\"_blank\"><u>following method</u></a>. If you experience any kind of issue, please let us know.\n",
                type: "warning",
                showCancelButton: false,
                confirmButtonText: "OK",
                containerClass: 'sweet-alert-lg',
                html: true
            }, function (isConfirm) {
                if (isConfirm) {
                    callback();
                    return;
                }
                    $form.find('input:checkbox').prop('checked', !state);

            });
            return;
        }

        if (beta) {
            swal({
                title: "Are you sure you want to enable it?",
                text: "Beta modules are compatible with all common systems but we're unable to test it in all conditions, so bugs can occur.",
                showCancelButton: true,
                confirmButtonText: "Yes"
            }, function (isConfirm) {
                if (isConfirm) {
                    callback();
                    return;
                }
                    $form.find('input:checkbox').prop('checked', !state);
            });
            return;
        }
    }

    callback();


    function callback() {
        $.ajax({
            url: '/servers/saveModules/',
            type: 'POST',
            data: $form.serialize(),
            success: function () {
                var $server_card = $('#server-card-' + server_id);
                if (typeof $server_card !== 'undefined') {
                    $('#server-card-' + server_id)
                        .find('[data-module="' + property + '"]')
                        .toggleClass('bg-danger bg-success');
                }

                if (reload) {
                    window.location.reload(true);
                }
            }
        });
    }
}
;

$(document).ready(function () {
    var hash = window.location.hash;

    if (hash) {
        $('ul.nav a[href="' + hash + '"]').tab('show');
    }

    $('.adv-search').on('click', '.dropdown-menu a', function (e) {
        e.preventDefault();

        $('.adv-search .search-label').text($(this).text());
        $('#search-type').val($(this).data('type'));
    });

});

$(function () {

    //
    // Bootstrap tooltip and popover
    //

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
});

function handleErrorMessage(response) {
    var error_message = response.responseText;
    error_message = 'An error happened, please try again!';
    if (error_message != '') {
        var errors;
        try {
            errors = JSON.parse(response.responseText);
        } catch (e) {
            $.ui.flashMsg({
                'text': error_message,
                'class': 'error'
            });

            return;
        }
        $.each(errors, function (_i, error) {
            $.each(error, function (field, message) {
                error_message = message;
                return;
            });
            return;
        });
    }

    $.ui.flashMsg({
        'text': error_message,
        'class': 'error'
    });

};