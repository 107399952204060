/*
 * Select control
 *
 */
    var initSelect2;

(function ($) {

    /*
     * Custom drop downs (Desktop only)
     */
    $(document).ready(function () {
        var formatSelectOption = function (state) {
            if (!state.id) {
                return state.text; // optgroup
            }

            var $option = $(state.element),
                iconClass = $option.data('icon'),
                imageSrc = $option.data('image');

            if (iconClass) {
                return '<i class="select-icon ' + iconClass + '"></i> ' + state.text;
            }

            if (imageSrc) {
                return '<img class="select-image" src="' + imageSrc + '" alt="" /> ' + state.text;
            }

            return state.text;
        };

        var selectOptions = {
            templateResult: formatSelectOption,
            templateSelection: formatSelectOption,
            escapeMarkup: function (m) {
                return m;
            },
            width: 'style'
        };

        initSelect2 = {
            init: function ($element) {
                var extraOptions = {
                    dropdownCssClass: '',
                    containerCssClass: ''
                };

                // Prevent duplicate loading
                if ($element.data('select2') != null) {
                    return true; // Continue
                }

                $element.attr('data-disposable', 'data-disposable');
                $element.one('dispose-control', function () {
                    if ($element.data('select2')) {
                        $element.select2('destroy');
                    }
                });

                if ($element.hasClass('select-no-search')) {
                    extraOptions.minimumResultsForSearch = Infinity;
                }

                if ($element.hasClass('select-no-dropdown')) {
                    extraOptions.dropdownCssClass += ' select-no-dropdown';
                    extraOptions.containerCssClass += ' select-no-dropdown';
                }

                if ($element.hasClass('select-hide-selected')) {
                    extraOptions.dropdownCssClass += ' select-hide-selected';
                }

                var separators = $element.data('token-separators');
                if (separators) {
                    extraOptions.tags = true;
                    extraOptions.tokenSeparators = separators.split('|');

                    /*
                     * When the dropdown is hidden, force the first option to be selected always.
                     */
                    if ($element.hasClass('select-no-dropdown')) {
                        extraOptions.selectOnClose = true;
                        extraOptions.closeOnSelect = false;

                        $element.on('select2:closing', function () {
                            $('.select2-dropdown.select-no-dropdown:first .select2-results__option--highlighted').removeClass('select2-results__option--highlighted');
                            $('.select2-dropdown.select-no-dropdown:first .select2-results__option:first').addClass('select2-results__option--highlighted');
                        });
                    }
                }

                var placeholder = $element.data('placeholder');
                if (placeholder) {
                    extraOptions.placeholder = placeholder;
                }

                $element.select2($.extend({}, selectOptions, extraOptions));
            }
        };

        /*
         * Bind custom select
         */
        $('select.custom-select').each(function () {
            initSelect2.init($(this));
        });
    });

    $(document).on('disable', 'select.custom-select', function (event, status) {
        if ($(this).data('select2') != null) {
            $(this).select2('enable', !status);
        }
    });

})(jQuery);