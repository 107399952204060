function checkLastCharge() {
    $.ajax({
        url: '/invoice/checkLastCharge',
        dataType: 'json',
        success: function (response) {
            var $alert = $('#last-transaction-unsuccessful');

            // Don't show the alert if has not reason.
            if (!response.hasOwnProperty('short_reason') || response.success) {
                $alert.addClass('hide');
                return;
            }

            document.getElementById('failed-charge-short-reason').innerHTML = response.short_reason;
            document.getElementById('failed-charge-reason-link').setAttribute('href', response.reason_link);
            var retryButton = document.getElementById('retry-failed-charge-button');

            if (response.remaining_attemps_for_retry_charge === 0) {
                retryButton.disabled = true;
                retryButton.title = "Your daily limit has been reached for retry charge. you can try again tomorrow.";
            } else {
                retryButton.disabled = false;
                retryButton.title = response.remaining_attemps_for_retry_charge + " tries left before manual retry is disabled for 24 hours";
            }

            $alert.removeClass('hide');
        }
    });
}

/* #### Ninja spinner start #### */
$(function () {
    if ($('.ninja-spinner-container').length == 1) {
        setTimeout(function () {
            //$('.ninja-spinner-container').fadeIn();  
            $('.ninja-spinner-container').show();
        }, 200);
    }
});
/* #### Ninja spinner end #### */


var Cookie = {
    set: function set(cName, cValue) {
      var d = new Date();
      d.setTime(d.getTime() + 60 * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
    },
    get: function get(cName) {
      var name = cName + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
  
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
  
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
  
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
  
      return "";
    }
  };

/* ### APIv2 Auth with axios #### */
// Axios wrapper for APIv2 to set API_JWT header       

if (typeof axios !== 'undefined') {
  ApiV2 = axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: "bearer ".concat(Cookie.get('API_JWT'))
    }
  });
}
/* ### APIv2 Auth with axios END #### */