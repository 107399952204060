/**
 * BitNinja  EditableFields module
 *
 * @module main
 */
function getEditableField(element, controller, event, append) {
    var _element = $(element);
    if (typeof append === 'undefined') {
        append = false;
    }
    controller = typeof controller === 'undefined' ? controller = "settings" : controller; // default controller is settings

    var fieldName = _element.attr('selectItem');
    var actionId = _element.attr('actionId');

    if (typeof event !== "undefined") {
        event.stopPropagation();
        event.preventDefault();
    }

    $.ajax({
        url: "/" + controller + "/editableField",
        type: "POST",
        data: {field: fieldName, actionId: actionId},
        beforeSend: function () {
            _element.addClass('disabled');
        },
        success: function (result) {
            _element.removeClass('disabled');
            localStorage.setItem(fieldName, $('#' + fieldName + 'FieldTr').html());
            if (!append) {
                $('#' + fieldName + 'FieldTr').html(result);
            } else {
                $('#' + fieldName + 'FieldTr .third-part').remove();
                $('#' + fieldName + 'FieldTr').parent().append(result);
            }
            if (controller == 'invoice' && fieldName == 'invoiceData') {
                changeCountry();
            }
        }
    }).done(function () {
        var _event = jQuery.Event('editField');
        $(document).trigger(_event);
    });
}

function resetField(element) {
    var fieldName = $(element).attr('selectItem');
    $('#editableFieldsForm' + fieldName).remove();
    var resetedFieldHtml = localStorage.getItem(fieldName);
    $('#' + fieldName + 'FieldTr').html(resetedFieldHtml);
}

function saveEditableField(element, controller) {
    var fieldName = $(element).attr('selectItem');

    if ((fieldName == 'email' || fieldName == 'password') && typeof notShowPersonalDataChangeAlert === 'undefined') {
        swal({
            title: 'Caution!',
            text: 'If you change your e-mail/password, you\'ll have to verify your e-mail address again.' +
                ' Are you sure you want to change your e-mail/password?',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }, function (isConfirm) {
            if (!isConfirm) {
                resetField(element);
                return;
            }
            notShowPersonalDataChangeAlert = true;
            saveEditableFieldAjax(element, controller);
        });

        return;
    }

    if (fieldName == 'send_license_key_email_to_users' && $('#editableFieldsForm' + fieldName + ' input[type=radio]:checked').val() !== '1') {
        swal({
            title: 'Caution!',
            text: 'Disabling this feature means that users will not receive their License keys via email.',
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }, function (isConfirm) {
            if (!isConfirm) {
                resetField(element);
                return;
            }
            notShowPersonalDataChangeAlert = true;
            saveEditableFieldAjax(element, controller);
        });

        return;
    }

    saveEditableFieldAjax(element, controller);
}

function saveEditableFieldAjax(element, controller) {
    var _element = $(element);

    controller = typeof controller === 'undefined' ? controller = "settings" : controller; //default controller is settings
    var fieldName = $(element).attr('selectItem');
    var actionId = $(element).attr('actionId');
    var editableFieldsForm = {};
    var file = null;
    var data = {};

    $('#editableFieldsForm' + fieldName + ' input, #editableFieldsForm' + fieldName + ' select, #editableFieldsForm' + fieldName + ' textarea').each(function (index) {
        var name = $(this).attr('id');
        if (typeof name !== 'undefined') {
            var charIndex = name.indexOf("_");
            name = name.substring((charIndex + 1), name.length);
            editableFieldsForm[name] = $(this).val();
            var type = $(this).attr('type');
            if (type === 'checkbox') {
                editableFieldsForm[name] = $(this).is(':checked') ? 1 : 0;
            }
            if (type === 'radio') {
                if ($('#editableFieldsForm' + fieldName + ' input[type=radio]').is(':checked')) {
                    editableFieldsForm[name.slice(-0, -2)] = $('#editableFieldsForm' + fieldName + ' input[type=radio]:checked').val();
                }
            }
            if(type === 'file'){
                if($(this).prop('files') && $(this).prop('files').length == 1){
                       file = $(this).prop('files')[0];
                }
            }
        }else{
            if($(this).attr('name') === "YII_CSRF_TOKEN"){
                data[$(this).attr('name')] = $(this).val();
            }
        }
    });
    if (fieldName == 'creditCard') {
        if ($('[name="g-recaptcha-response"]').length != 0) {
            data['g-recaptcha-response'] = $('[name="g-recaptcha-response"]').val();
        }
    }

    if(editableFieldsForm.nonce === ''){
        validateCreditCard(actionId);
        return;
    }

    data.field = fieldName;
    data.actionId = actionId;
    var url = $('#editableFieldsForm' + fieldName).attr('action');

    const form = new FormData();

    if(file){
        form.append('HeaderLogoUrlForm[header_logo_url]',file);
    }

    for(var k in data){
        form.append(k,data[k]);
    }
    for(var key in editableFieldsForm){
        form.append('editableFieldsForm['+key+']',editableFieldsForm[key]);
    }

    $.ajax({
        url: url,
        type: "POST",
        processData: false,
        contentType: false,
        data: form,
        beforeSend: function () {
            _element.addClass("disabled");
        },
        success: function (result) {
            $('#saveButton[selectitem="' + fieldName + '"]').html('Save');
            if (result !== 'success' && result.substring(1, result.length) != 'success') {
                var formData = $(result).filter('#editableFieldsForm' + fieldName).html();
                $('#editableFieldsForm' + fieldName).html(formData);
            }
            else {
                if(fieldName === 'creditCard' && actionId === 'paymentMethod'){
                    checkLastCharge();
                }

                resetField(element);
                $('#editButton[selectitem="' + fieldName + '"] #successPipe').fadeIn('fast');
                setTimeout(function () {
                    $('#editButton[selectitem="' + fieldName + '"] #successPipe').fadeOut('slow');
                }, 1000);

                if (fieldName === 'timezone') {
                    date = 0;
                    $("#eventTable").html('');
                }

                if (fieldName == 'email' || fieldName == 'password') {
                    $('#' + fieldName + 'FieldTr .second-part').text(editableFieldsForm[fieldName]);
                    setTimeout(function () {
                        window.location.href = '/site/login';
                    }, 5000);
                }

                else if (fieldName !== 'password') {
                    $.ajax({
                        url: "/" + controller + "/" + actionId,
                        type: "POST",
                        dataType: "html",
                        success: function (result) {
                            var fieldValue;
                            if (fieldName === 'creditCard' || fieldName === 'invoiceData' || fieldName === 'paymentCycle' || fieldName === 'header_logo_url') {
                                fieldValue = $(result).find('#' + fieldName + 'FieldTr').html();
                                $('#' + fieldName + 'FieldTr').html(fieldValue);
                            }
                            else {
                                fieldValue = $(result).find('#' + fieldName + 'FieldTr div').eq(1).html();
                                $('#' + fieldName + 'FieldTr div').eq(1).html(fieldValue);
                            }
                        }
                    });
                }
            }

            if (typeof clientToken !== 'undefined' && $('#paypal-button').length) {
                setupBraintree(clientToken, 'paymentMethod');
            }
        }
    });
}


function deleteUploadedFile(element) {
    if (confirm('Are you sure to delete this header logo?')) {
        $.ajax({
            url:'/preferences/deleteUploadedFile',
            type:'GET',
            success:function(result){
                $.ajax({
                    url: "/preferences/index",
                    type: "POST",
                    dataType: "html",
                    success: function (result) {
                        var fieldValue;
                        fieldValue = $(result).find('#header_logo_urlFieldTr').html();
                        $('#header_logo_urlFieldTr').html(fieldValue);
                    }
                });
            }
        })
    }
}