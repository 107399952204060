+function ($) {
    'use strict';

    var BarChart = function(element, options) {
        var $el = this.$el = $(element);
        this.options = options || {};

        var type = 'bar';
        var ctx = $el.get(0).getContext("2d");
        var title = this.options.title || false;

        var data = {
            labels: options.labels,
            datasets: [{
                data: options.dataset,
                pointRadius: 1,
                backgroundColor: 'rgba(124,181,236,0.5)',
                borderColor: 'rgba(124,181,236,1)'
            }]
        };

        var $options = {
            tooltips: {
                enabled: true
            },
            legend: {
                display: false
            },
            title: {
                display: !(title == false),
                text: title
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    },
                    display: true
                }],
                xAxes: [{
                    display: true,
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 10
                    }
                }]
            },
            scaleGridLineColor: "rgba(0, 0, 0, .05)",
            responsive: true
        };

        if (this.options.type) {
            type = this.options.type;
        }

        if (this.options.tooltips == false) {
            $options.tooltips.enabled = false
        }

        if (this.options.xAxes == false) {
            $options.scales.xAxes = [{
                display: false
            }]
        }

        if (this.options.yAxes == false) {
            $options.scales.yAxes = [{
                display: false
            }]
        }

        new Chart(ctx, {type: type, data: data, options: $options});
    };

    // BARCHART PLUGIN DEFINITION
    // ============================

    var old = $.fn.barChart;

    $.fn.barChart = function (option) {
        return this.each(function () {
            var $this = $(this);
            var data  = $this.data('ui.barChart');
            var options = $.extend({}, BarChart.DEFAULTS, $this.data(), typeof option == 'object' && option);

            if (!data) {
                $this.data('ui.barChart', new BarChart(this, options))
            }
        });
    };

    $.fn.barChart.Constructor = BarChart;

    // BARCHART NO CONFLICT
    // =================

    $.fn.barChart.noConflict = function () {
        $.fn.barChart = old;
        return this
    };

    // BARCHART DATA-API
    // ===============

    $(document).ready(function () {
        $('[data-control=chart-bar]').barChart()
    })

}(window.jQuery);