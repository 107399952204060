/**
 * Closes an open NinjaDialog called from anywhere inside a referenced page
 */
var closeNinjaDialogFromOutside;

/**
 * A global variable to access the NinjaDialog module's functions
 */
var NinjaDialog;

/**
 * NinjaDialog module
 * 
 * Dependent modules: 'jquery'
 * 
 * @module ninjaDialog
 */
(function ($) {

    /**
     * Closes an open NinjaDialog called from anywhere inside a referenced page
     * 
     * @param  element A DOM element speficying the NinjaDialog to handle
     * @return {undefined}
     */
    closeNinjaDialogFromOutside = function(element) {
        var id = '#' + $(element).data('ninjadialogid');
        $(id).removeClass('dialog--open');
        $(id).addClass('dialog--close');

        setTimeout(function () {
            $(id).remove();
        }, 500);
        $('html').css('overflow', 'auto');
    };

    /**
     * Creates and initializes a new NinjaDialog window
     * 
     * @public
     * @param {String}   name        The unique name of the NinjaDialog window
     * @param {Boolean}  autoOpen    Should the NinjaDialog open automatically on page load
     * @param {Function} callback    A callback function to call after initialization
     * @param {object}   ajaxOptions [description]
     * @param {array}    getParams   [description]
     */
    NinjaDialog = function(name, autoOpen, callback, ajaxOptions, getParams) {
        this.el = $('#' + name + '.dialog')[0];
        this.id = '#' + name;
        this.name = name;
        this.autoOpen = autoOpen;
        this.ajaxOptions = ajaxOptions;
        this.getParams = getParams;
        this.callback = callback;
        this._initEvents();
    };

    NinjaDialog.prototype.close = function (event) {
        var self;
        if (typeof event !== 'undefined') {
            self = event.data.self;
        }
        else {
            self = this;
        }
        $(self.id).removeClass('dialog--open');
        $(self.id).addClass('dialog--close');

        setTimeout(function () {
            $(document).unbind('keydown', self.keyDown);
            if (typeof self.ajaxRunner !== 'undefined') {
                $(self.id + ' button.closeButton').bind('click', {self: self}, self.close);
                $(self.id + ' button.submitButton').unbind('click', self.ajaxRunner);
            }
            $(self.id).remove();
        }, 500);

        $('html').css('overflow', 'auto');
    };

    NinjaDialog.prototype._initEvents = function () {
        var self = this;
        $(document).bind('keydown', {self: self}, self.keyDown);
        if (self.autoOpen) {
            self.open();
        }
    };

    NinjaDialog.prototype.keyDown = function (e) {
        var self = e.data.self;
        var keyCode = e.keyCode || e.which;
        if (keyCode === 27) {
            self.close();
        }
    };
    
    NinjaDialog.prototype.ajaxRunner = function (event) {
        var self = event.data.self;
        var data = {};
        var obj = {};

        if (typeof self.getParams === 'undefined') {
            var modelName = $('form button.submitButton').attr('model');
            $(self.id + ' form input').each(function (index) {
                var name = $(this).attr('id');
                if (typeof name !== 'undefined') {
                    var charIndex = name.indexOf("_");
                    name = name.substring((charIndex + 1), name.length);
                    data[name] = $(this).val();
                }
            });

            $(self.id + ' form textarea').each(function (index) {
                var name = $(this).attr('id');
                var charIndex = name.indexOf("_");
                name = name.substring((charIndex + 1), name.length);
                data[name] = $(this).val();
            });

            data['submitButton'] = true;

            obj[modelName] = data;
        }
        else {
            for (var index in self.getParams) {
                self.getParams[index] = $(self.getParams[index]).val();
            }
            obj = self.getParams;
        }

        if (typeof self.ajaxOptions.data !== 'undefined') {
            for (var index in self.ajaxOptions.data) {
                obj[index] = self.ajaxOptions.data[index];
            }
        }

        $.ajax({
            url: typeof self.ajaxOptions.url !== 'undefined' ? self.ajaxOptions.url : $(self.id + ' form').attr('action'),
            type: self.ajaxOptions.type,
            data: obj,
            success: function (result) {
                if (typeof self.callback === "function") {
                    self.callback();
                }

                if (result != '') {
                    $(self.id + ' .dialog__content').html(result);
                }
                else {
                    self.success();
                }
                
                self.bindEvents();
            }
        });
    };

    NinjaDialog.prototype.success = function () {
        var self = this;
        $(self.id + ' .dialog__content').html(
                '<div class="sweet-alert" tabIndex="-1">\n\
                    <div class="icon success">\n\
                        <span class="line tip"></span>\n\
                        <span class="line long"></span>\n\
                        <div class="placeholder"></div>\n\
                    <div class="fix"></div>\n\
                </div>\n\
                <h2>Success!</h2>\n\
</div>');
        $('div.icon.success').addClass('animate');
        $('div.icon.success .tip').addClass('animateSuccessTip');
        $('div.icon.success .long').addClass('animateSuccessLong');

        $("body > div > .sweet-alert").remove();
        $('.sweet-alert').show();
        setTimeout(function () {
            self.close();
            $(".sweet-alert").hide();
        }, 2000);
    };

    NinjaDialog.prototype.open = function () {
        var self = this;
        $('body').append('<div id="' + self.name + '" class="dialog">\n\
                        <div class="dialog__overlay"></div>\n\
                        <div class="dialog__content">\n\
                            <div class="spinner">\n\
                              <div class="rect1"></div>\n\
                              <div class="rect2"></div>\n\
                              <div class="rect3"></div>\n\
                              <div class="rect4"></div>\n\
                              <div class="rect5"></div>\n\
                            </div>                        \n\
                        </div></div>');
        $(self.id).addClass('dialog--open');
        $(self.id).show();
        $('html').css('overflow', 'hidden');
    };
    
    NinjaDialog.prototype.bindEvents = function() {
        var self = this;
        
        $(document).bind('keydown', {self: self}, self.keyDown);
        $(self.id + ' button.closeButton').bind('click', {self: self}, self.close);
        $(self.id + ' button.cancelButton').bind('click', {self: self}, self.close);
        if (typeof self.ajaxOptions !== 'undefined') {
            $(self.id + ' button.submitButton').bind('click', {self: self}, self.ajaxRunner);
        }
    };

    NinjaDialog.prototype.setText = function (text) {
        var self = this;
        $(self.id + ' .dialog__content').html(text);

        self.bindEvents();
    };

    window.NinjaDialog = NinjaDialog;

})(jQuery);