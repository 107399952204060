(function($){

    if ($.ui === undefined)
        $.ui = {};

    $.ui.alert = function alert(message) {
        swal({
            title: message,
            confirmButtonClass: 'btn-primary'
        })
    };

    $.ui.confirm = function confirm(message, callback) {

        swal({
            title: "Are you sure?",
            text: message,
            showCancelButton: true,
            confirmButtonClass: 'btn-primary'
        }, callback)

    };

})(jQuery);
